import * as React from 'react';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { SizeMe } from 'react-sizeme';
import { useState, useEffect, useRef } from 'react';
import { loadTheme } from 'office-ui-fabric-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect, Link } from 'react-router-dom'
import { useBetween } from "use-between";


initializeIcons();

loadTheme({
  palette: {
    'neutralLighter': '#7692dd', // Color when you hiver on the side nav menus
    'neutralLight': '#7692dd'  // Color after you click on any of the side nav menus
  }
});

//const navStyles  : Partial<INavStyles> = {
const navStyles = (tabColor: any, tab_width: any) => ({

  root: {
    width: tab_width,
    //height: 705,
    //height: '93.4vh',
    //height: '100%'
    boxSizing: 'border-box',
    border: '3px solid #eee',
    overflowY: 'auto',
    backgroundColor: 'white',
    height: '93.4vh'
  },
  linkText: {
    color: 'black',
    fontWeight: '650',
  },
  compositeLink: {
    backgroundColor: 'white'
  },
});

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: '',
        url: '',
        key: "toggle_hide",
        icon: "GlobalNavButton",

      },
      // {
      //   name: 'Home',
      //   url: '',
      //   key: 'Home',
      //   expandAriaLabel: 'Expand Home section',
      //   collapseAriaLabel: 'Collapse Home section',
      //   icon: 'HomeSolid',
      //   isExpanded: true,
      // },

      {
        name: 'All Subscription Cost Analysis',
        url: '',
        icon: 'BarcHart4',
        key: 'AllSubscription'


      },
      {
        name: 'Index Prod Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Prod Subscription',
            url: '',
            key: 'ProdSubscription'

          },
          {
            name: 'Prod Kubernetes',
            url: '',
            key: 'KubeProd'

          },
          {
            name: 'Orphan Disk Prod',
            url: '',
            key: 'DiskP'
          },
          {
            name: 'Orphan Network Prod',
            url: '',
            key: 'OrphanNetworkP'
          },
          {
            name: 'Orphan Resource Group Prod',
            url: '',
            key: 'OrphanResourceGroupP'
          },
          {
            name: 'Orphan App Service Prod',
            url: '',
            key: 'OrphanAppServiceP'
          },
          {
            name: 'Orphan Public IP Prod',
            url: '',
            key: 'OrphanPublicP'
          },
          {
            name: 'Orphan NSG Prod',
            url: '',
            key: 'OrphanNSGP'
          },
        ],
        isExpanded: false,

      },
      {
        name: 'Index Non-Prod Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Non-Prod Subscription',
            url: '',
            key: 'nonprodcost'

          },
          {
            name: 'Non-Prod Kubernetes',
            url: '',
            key: 'Kubecost'
          },
          {
            name: 'Orphan Disk',
            url: '',
            key: 'Disk'
          },
          {
            name: 'Orphan Network',
            url: '',
            key: 'OrphanNetwork'
          },
          {
            name: 'Orphan Public IP',
            url: '',
            key: 'OrphanPublicIP'
          },
          {
            name: 'Orphan Resource Group',
            url: '',
            key: 'OrphanResourceGroup'
          },
          {
            name: 'Orphan NSG',
            url: '',
            key: 'OrphanNSG'
          },
          {
            name: 'Orphan App Service',
            url: '',
            key: 'OrphanAppService'
          },
        ],
        isExpanded: false,

      },
      {
        name: 'Index V2 Non-Prod Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Orphan Disk V2NP',
            url: '',
            key: 'DiskV2NP'
          },
          {
            name: 'Orphan Network V2NP',
            url: '',
            key: 'OrphanNetworkV2NP'
          },
          {
            name: 'Orphan Public IP V2NP',
            url: '',
            key: 'OrphanPublicIPV2NP'
          },
          {
            name: 'Orphan Resource Group V2NP',
            url: '',
            key: 'OrphanResourceGroupV2NP'
          },
          {
            name: 'Orphan NSG V2NP',
            url: '',
            key: 'OrphanNSGV2NP'
          },
          {
            name: 'Orphan App Service V2NP',
            url: '',
            key: 'OrphanAppServiceV2NP'
          },
        ],
        isExpanded: false,

      },
      {
        name: 'Index V2 Prod Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Orphan Disk V2P',
            url: '',
            key: 'DiskV2P'
          },
          {
            name: 'Orphan Network V2P',
            url: '',
            key: 'OrphanNetworkV2P'
          },
          {
            name: 'Orphan Public IP V2P',
            url: '',
            key: 'OrphanPublicIPV2P'
          },
          {
            name: 'Orphan Resource Group V2P',
            url: '',
            key: 'OrphanResourceGroupV2P'
          },
          {
            name: 'Orphan NSG V2P',
            url: '',
            key: 'OrphanNSGV2P'
          },
          {
            name: 'Orphan App Service V2P',
            url: '',
            key: 'OrphanAppServiceV2P'
          },
        ],
        isExpanded: false,

      },
      {
        name: 'Index SRE_Sandbox Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Sandbox Subscription',
            url: '',
            key: 'Sandbox'

          },
          {
            name: 'Orphan Disk SRE',
            url: '',
            key: 'DiskSRE'
          },
          {
            name: 'Orphan Network SRE',
            url: '',
            key: 'OrphanNetworkSRE'
          },
          {
            name: 'Orphan Public IP SRE',
            url: '',
            key: 'OrphanPublicIPSRE'
          },
          {
            name: 'Orphan Resource Group SRE',
            url: '',
            key: 'OrphanResourceGroupSRE'
          },
          {
            name: 'Orphan NSG SRE',
            url: '',
            key: 'OrphanNSGSRE'
          },
          {
            name: 'Orphan App Service SRE',
            url: '',
            key: 'OrphanAppServiceSRE'
          },
        ],
        isExpanded: false,

      },
      {
        name: 'Index DR',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'IndexV2_Prod',
            url: '',
            key: 'IndexV2Prod'

          },
          {
            name: 'IndexV2_Non-Prod',
            url: '',
            key: 'IndexV2NonProd'
          },
        ],
        isExpanded: false,

      },
    ],
  },
];


// Hook
function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useFormState = () => {
  const [currentTab, setTab] = useState('Subscription');
  const prevTab = '' + usePrevious(currentTab);

  const [currentTabColor, setTabColor] = useState('White');
  const [currentTabWidth, setTabWidth] = useState(208);

  return {
    currentTab, setTab, prevTab, currentTabColor, setTabColor, currentTabWidth, setTabWidth
  };
};



export const SideBar: React.FunctionComponent = () => {

  const useSharedFormState = () => useBetween(useFormState);
  const { currentTab, setTab } = useSharedFormState();
  const { prevTab } = useSharedFormState();
  const { currentTabColor, setTabColor } = useSharedFormState();
  const { currentTabWidth, setTabWidth } = useSharedFormState();


  return (
    <>


      {/* {currentTab === 'Home' && <Redirect to='/home' />} */}
      {currentTab === 'Configure Rules' && <Redirect to='configurerules' />}
      {currentTab === 'View Rules' && <Redirect to='viewrules' />}
      {currentTab === 'Reporting' && <Redirect to='reporting' />}
      {currentTab === 'All Subscription Cost Analysis' && <Redirect to='AllSubscription' />}

      {currentTab === 'Prod Subscription' && <Redirect to='ProdSubscription' />}

      {currentTab === 'Prod Kubernetes' && <Redirect to='KubeProd' />}

      {currentTab === 'Non-Prod Subscription' && <Redirect to='nonprodcost' />}

      {currentTab === 'Non-Prod Kubernetes' && <Redirect to='Kubecost' />}

      {currentTab === 'Sandbox Subscription' && <Redirect to='Sandbox' />}

      {currentTab === 'IndexV2_Prod' && <Redirect to='IndexV2_Prod' />}

      {currentTab === 'IndexV2_Non-Prod' && <Redirect to='IndexV2_Non-Prod' />}
      
      {currentTab === 'Orphan Disk' && <Redirect to='Disk' />}
      {currentTab === 'Orphan Network' && <Redirect to='OrphanNetwork' />}
      {currentTab === 'Orphan Network Prod' && <Redirect to='OrphanNetworkP' />}
      {currentTab === 'Orphan Public IP' && <Redirect to='OrphanPublic' />}
      {currentTab === 'Orphan Public IP Prod' && <Redirect to='OrphanPublicP' />}
      {currentTab === 'Orphan Resource Group' && <Redirect to='OrphanResourceGroup' />}
      {currentTab === 'Orphan Resource Group Prod' && <Redirect to='OrphanResourceGroupP' />}
      {currentTab === 'Orphan NSG' && <Redirect to='OrphanNSG' />}
      {currentTab === 'Orphan App Service' && <Redirect to='OrphanAppService' />}
      {currentTab === 'Orphan App Service Prod' && <Redirect to='OrphanAppServiceP' />}
      {currentTab === 'Orphan Disk Prod' && <Redirect to='DiskP' />}
      {currentTab === 'Orphan NSG Prod' && <Redirect to='OrphanNSGP' />}

      {currentTab === 'Orphan Disk SRE' && <Redirect to='DiskSRE' />}
      {currentTab === 'Orphan Network SRE' && <Redirect to='OrphanNetworkSRE' />}
      {currentTab === 'Orphan Public IP SRE' && <Redirect to='OrphanPublicSRE' />}
      {currentTab === 'Orphan Resource Group SRE' && <Redirect to='OrphanResourceGroupSRE' />}
      {currentTab === 'Orphan NSG SRE' && <Redirect to='OrphanNSGSRE' />}
      {currentTab === 'Orphan App Service SRE' && <Redirect to='OrphanAppServiceSRE' />}

      {currentTab === 'Orphan Disk V2NP' && <Redirect to='DiskV2NP' />}
      {currentTab === 'Orphan Network V2NP' && <Redirect to='OrphanNetworkV2NP' />}
      {currentTab === 'Orphan Public IP V2NP' && <Redirect to='OrphanPublicV2NP' />}
      {currentTab === 'Orphan Resource Group V2NP' && <Redirect to='OrphanResourceGroupV2NP' />}
      {currentTab === 'Orphan NSG V2NP' && <Redirect to='OrphanNSGV2NP' />}
      {currentTab === 'Orphan App Service V2NP' && <Redirect to='OrphanAppServiceV2NP' />}

      {currentTab === 'Orphan Disk V2P' && <Redirect to='DiskV2P' />}
      {currentTab === 'Orphan Network V2P' && <Redirect to='OrphanNetworkV2P' />}
      {currentTab === 'Orphan Public IP V2P' && <Redirect to='OrphanPublicV2P' />}
      {currentTab === 'Orphan Resource Group V2P' && <Redirect to='OrphanResourceGroupV2P' />}
      {currentTab === 'Orphan NSG V2P' && <Redirect to='OrphanNSGV2P' />}
      {currentTab === 'Orphan App Service V2P' && <Redirect to='OrphanAppServiceV2P' />}

      {/* {console.log(currentTab)} */}


      <SizeMe monitorHeight>
        {({ size }) => (
          <Container fluid style={{ height: '100%', paddingLeft: 0, paddingRight: 0, }}>
            <Row style={{ height: '100%', marginLeft: 0, marginRight: 0 }}>
              <Col style={{ height: '100%', paddingLeft: 0, paddingRight: 0, backgroundColor: 'white' }}>
                <Nav
                  //onLinkClick={(ev?: React.MouseEvent,item?:INavLink)=>console.log(item?.name)}
                  onLinkClick={(ev?: React.MouseEvent, item?: INavLink) => (item?.name === '' ? (currentTabWidth === 208 ? ([setTabWidth(50), setTab(currentTab)]) : ([setTabWidth(208), setTab(currentTab)])) : setTab('' + item?.name))}
                  selectedKey={currentTab}
                  ariaLabel="Nav basic example"
                  styles={navStyles(currentTabColor, currentTabWidth)}
                  groups={navLinkGroups}
                />
              </Col>
            </Row>
            {/* https://www.freakyjolly.com/react-how-to-get-height-and-width-of-component-container/ */}
          </Container>
        )}
      </SizeMe>
    </>
  );
};


function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
  alert(item?.name + ' tab clicked');
  return item?.name;

}

export const globalVariable = {
  INIT_FONT_SIZE: 16,
};

export var globalVariable2 = 'Hello'


export var xyz = (ev: any) => {
  return ev;
}
