
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


class MainContentHome extends Component {

  constructor(props) {  
    super(props)  

    this.state = {           
      number:0,
      toggle:false
    }  
}  

  render() {
    return (
      <>
        {  
        <Container>
          <Row>&nbsp;</Row>
          <Row>
          <h5> Welcome to Index Cost Report </h5>
          </Row>
      <Row>
       <h5></h5>
       </Row>
        </Container>
        }
             
      </>
    );
  }
}

export default MainContentHome;