import * as React from 'react';
import { AnimationClassNames, mergeStyles, getTheme } from '@fluentui/react/lib/Styling';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const TopBar: React.FunctionComponent = (param:any) => {

  return (  
    <>
      <div className={contentMSCIClass}>
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Row style={{ marginLeft: 0, marginRight: 0}}>
            <Col>MSCI</Col>
            <Col xs={5} className='d-flex align-items-center justify-content-center'> Azure Index Cost Report </Col>
            <Col >
            <Row >
            <div style={{display:'flex',justifyContent: 'right'}}>
              <div style={{color:'white',fontSize:'20px',paddingLeft: 0, paddingRight: 0,textAlign:'right'}}>Welcome</div>
              <div style={{color:'#FFFF00',fontSize:'20px',paddingLeft: 0, paddingRight: 0,textAlign:'left'}}>&nbsp; {param.username.split(',')[1]}</div>
            </div>
             {/* <Col style={{color:'white',fontSize:'20px',paddingLeft: 0, paddingRight: 0,textAlign:'right'}}> <div > Welcome </div> </Col>
            <Col style={{color:'#FFFF00',fontSize:'20px',paddingLeft: 0, paddingRight: 0,textAlign:'left'}}> <div > &nbsp; {param.username.split(',')[1]}</div> </Col> */}
            </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const theme = getTheme();
const contentMSCIClass = mergeStyles([
  {
    //backgroundColor: theme.palette.themePrimary,
    backgroundColor: '#7692dd',
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
    fontSize: '25px',
    fontWeight: '549'
  },
  AnimationClassNames.scaleUpIn100,
]);
