import React from "react";
import "../css/LoginPage.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom'
import { useBetween } from "use-between";
import { useState } from 'react';


export const useLoginState = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState('NA');

  return {
    username, setUsername, password, setPassword, loggedIn, setLoggedIn
  };
};



export default function LoginPage() {

  const useSharedFormState = () => useBetween(useLoginState);
  const { username, setUsername } = useSharedFormState();
  const { password, setPassword } = useSharedFormState();
  const { loggedIn, setLoggedIn } = useSharedFormState();

  
  return (
    <>
      {
        loggedIn === 'yes' &&
        <>
          return <Redirect to='/Subscription' />
        </>
      }
      {
        ((loggedIn === 'unauthorized') || (loggedIn === 'NA')) &&
        <div className="login">
          <Container >
            <Row>
              <h2 style={{ textAlign: 'center', color: '#ffffff', padding: '20px' }}>COFEES Login</h2>
            </Row>
            <form id="login" autoComplete="yes" onSubmit={(event) => { event.preventDefault(); username === 'cofees' && password === 'cofees' ? setLoggedIn('yes') : setLoggedIn('unauthorized') }} >
              <Row>
                <Col><input type="text" name="Uname" id="Uname" placeholder="Username" required="required" value={username} onChange={(event) => { setUsername(event.target.value) }} /></Col>

              </Row>
              <Row>&nbsp;</Row>
              <Row>
                <Col><input type="Password" name="Pass" id="Pass" placeholder="Password" required="required" value={password} onChange={(event) => { setPassword(event.target.value) }} /></Col>
              </Row>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Row>
                <Col className='d-flex align-items-center justify-content-center'><input type="submit" name="log" id="log" value="Login" /></Col>
                <br /><br />
              </Row>
              <Row>&nbsp;</Row>

              <Row>
                <Col className='d-flex align-items-center justify-content-center'><h4 style={{ color: '#2a45af' }}>&nbsp; {loggedIn === 'unauthorized' && <b>Invalid credentials</b>}</h4></Col>
              </Row>

            </form>
          </Container>
        </div>
      }

    </>
  );
}