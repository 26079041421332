import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginPage from "./LoginPage";
import { TopBar } from './TopBar'
import { SideBar } from './SideBar'
import MainContentHome from './MainContentHome'
import MainContentRulesConfigure from './MainContentRulesConfigure'
import MainContentViewRules from './MainContentViewRules'
import MainContentReporting from './MainContentReporting'
import { useBetween } from "use-between";
import { useFormState } from './SideBar'
import { useLoginState } from './LoginPage'
import { Redirect } from 'react-router-dom'
import { authProvider } from "./authProvider";
import { AzureAD, AuthenticationState } from 'react-aad-msal';



export default function AppRouter2(param) {

  const useSharedFormState = () => useBetween(useFormState);
  const { currentTab, currentTabWidth } = useSharedFormState();


  const useSharedLoginState = () => useBetween(useLoginState);
  const { loggedIn, setLoggedIn } = useSharedLoginState();
  return (
    <Router>
      <Switch>

         {/* <Route exact path="/">
          <Container fluid>
            <Row style={{ height: '100vh', backgroundColor: 'grey' }}>
              <Col className='border d-flex align-items-center justify-content-center' style={{ backgroundColor: '#e3e9f8' }} >
                <LoginPage />
              </Col>
            </Row>
          </Container>
        </Route>  */}

          <Route exact path="/">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                  <MainContentHome />
                </Col>
              </Row>
            </Container>
          </Route>
   

        {/* To avoid margins on left and right side INSIDE the column, add padding on either side */}

        {/* {loggedIn === 'yes' ? */}
      
          {/* <Route exact path="/home"> */}
         
          <Route exact path="/home">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                  <MainContentHome />
                </Col>
              </Row>
            </Container>
          </Route>
      
          {/* : <Redirect to='/' />
        } */}


          <Route exact path="/configurerules">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ maxWidth: currentTabWidth, paddingLeft: 0, paddingRight: 0 }}>
                  <SideBar />
                </Col>
                <Col>
                  <MainContentRulesConfigure email={param.email}/>
                </Col>
              </Row>
            </Container>
          </Route>


    
          <Route exact path="/viewrules">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                  <MainContentViewRules />
                </Col>
              </Row>
            </Container>
          </Route>



          <Route exact path="/reporting">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                  <MainContentReporting/>
                </Col>
              </Row>
            </Container>
          </Route>
            <Route exact path="/AllSubscription">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=1af927f8-2d15-4ec9-9d00-7f2f6892985d&appId=dc4dac2d-5c83-4c63-863a-0baa4e4aef9d&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/orphan">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" href="https://portal.azure.com/#blade/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Ff19a4989-b920-4ecb-91b7-fdeb5090c024%2FresourceGroups%2Frgrp-uva2-mt-sharedAppinsgtUAT/ConfigurationId/%2Fsubscriptions%2Ff19a4989-b920-4ecb-91b7-fdeb5090c024%2Fresourcegroups%2Frgrp-uva2-mt-sharedappinsgtuat%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F58a1df59-f1c0-4a2c-aad9-67b47a0d7014/Type/workbook/WorkbookTemplateName/Index%20Azure%20Orphan%20Resources" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/nonprodcost">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" target="_blank" src="https://app.powerbi.com/reportEmbed?reportId=ca053089-10e7-4063-a0a3-220492851434&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/ProdSubscription">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=a931bdbc-865d-462a-9dec-f31e32565fcf&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/IndexV2_Prod">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=4672b2b7-a1de-402f-a3d9-bed2908d49a2&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/IndexV2_Non-Prod">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=61cca130-d4e7-406a-8b8e-0c2fb67f4c43&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/KubeProd">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://kubecost-eod.index-prod.msciapps.com/" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/Kubecost">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://kubecost-eod.index-non-prod.msciapps.com/allocations.html" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/Disk">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=645e3d55-622c-46c9-bae4-cf9da0000baf&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanAppService">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=47a1ea24-aef0-4134-bfcc-f62a7b06ca21&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanAppServiceP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=6e99f7a5-3926-49b9-9dea-d831c32a45f9&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanAppServiceV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=fc200f21-c079-4861-83d5-5e38250b14d2&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanAppServiceV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=fc200f21-c079-4861-83d5-5e38250b14d2&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanAppServiceSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=2b91738b-3240-49bd-863c-721b54f293c3&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/DiskP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=02505d25-fa4f-488a-9761-df921bb02f09&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanNetwork">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=fbd3d60d-7504-4cfc-9416-fca02efaff7e&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
            <Route exact path="/OrphanPublicP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=f2b42c8b-5dfb-4b37-8271-d9f59feacb05&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanPublicV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=11722284-59e0-4bbb-8ff3-415531fe4717&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanPublicV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=6e362a3b-8c9d-4954-9de6-2afcf7b25734&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanPublic">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=4b3a137e-00b1-476f-9609-c62df79a4da9&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanPublicP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=f2b42c8b-5dfb-4b37-8271-d9f59feacb05&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/OrphanResourceGroup">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=feb6d295-daa3-4b47-8feb-1e6c30903ff5&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanResourceGroupP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=22f20801-7a71-43c3-80d4-874d3478df28&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanResourceGroupV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=5da4185d-91df-46ed-ab6b-d675a3e05675&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanResourceGroupV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=3fd456df-92f5-465c-a77e-87bb74b4bc98&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanNSG">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=68f769a3-147e-43a0-a301-f1cb8cad8163&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanNSGP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=d7c8d4cf-d409-4497-a492-e4435dafe8bb&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D " frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanNSGV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=9423eadc-ff9d-4ced-80d7-44a926ed53d9&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanNSGV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=c70aeb10-1ec1-4e15-a634-2131ff721599&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/OrphanNetworkSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=6892111d-6f06-4735-9625-d10c510943a8&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanNetworkV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=be6b5700-0baa-40c6-b0a2-46fd6746203d&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanNetworkV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=be6b5700-0baa-40c6-b0a2-46fd6746203d&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanPublicV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=6e362a3b-8c9d-4954-9de6-2afcf7b25734&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/OrphanPublicV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=11722284-59e0-4bbb-8ff3-415531fe4717&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanResourceGroupV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=5da4185d-91df-46ed-ab6b-d675a3e05675&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/OrphanResourceGroupV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=ae0e623e-c73a-42eb-973f-6d714e33e5aa&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>




          <Route exact path="/OrphanPublicSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=9c407aa9-b385-462b-81ff-dcc8b5888a4f&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" 
                frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
         
          <Route exact path="/OrphanResourceGroupSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=ae0e623e-c73a-42eb-973f-6d714e33e5aa&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/DiskSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=8ebd5ed2-f57c-4d84-86bf-95f2194cc979&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/DiskV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=2a764e06-021c-4cd1-875a-8cdde68a6459&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>

          <Route exact path="/DiskV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=cfae6ab6-ef74-4c77-80a2-58fff808e6ca&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          <Route exact path="/OrphanNSGV2NP">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=c70aeb10-1ec1-4e15-a634-2131ff721599&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/OrphanNSGV2P">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=9423eadc-ff9d-4ced-80d7-44a926ed53d9&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>




          <Route exact path="/OrphanNSGSRE">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" 
                src="https://app.powerbi.com/reportEmbed?reportId=2b91738b-3240-49bd-863c-721b54f293c3&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>


          <Route exact path="/Sandbox">
            <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
                <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                  <TopBar username={param.username}/>
                </Col>
              </Row>
              <Row style={{ marginLeft: 0, marginRight: 0 }}>
                {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
                <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                  <SideBar />
                </Col>
                <Col >
                <iframe title="Azure Index Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=a04e7fad-adbf-4610-8150-7049a30afeb1&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
              </Row>
            </Container>
          </Route>
          
      </Switch>
    </Router>
  );
}